import React from "react";

import "./SinglePage.css";

import TopBar from "../components/TopBar";

const SinglePage = (props) => {
  const lang = props.lang.toString().toLowerCase();
  const directory = lang === "it" ? "/it/supporto/donare" : lang === "fr" ? "/fr/soutenir/dons" : "/de/helfen/spenden";
  const spendenUrl = `https://www.projuventute.ch${directory}`;
  let ctaText = lang === "it" ? "donare" : lang === "fr" ? "faire un don" : lang === "en" ? "donate now" : "jetzt spenden";
  ctaText = ctaText.toUpperCase();
  
  return (
    
    <article className="single--page">
      <TopBar title={props.title} lang={props.lang} />
      <div className="img--block">
        <p className="text--oben">{props.textoben}</p>
        <img src={props.bild} className={props.spenden && "bild--spenden"} alt="Start Bild" />
        <p
          className={`text--unten ${props.textKleiner && "text--kleiner"} ${props.textKleinster && "text--kleinster"} ${props.textMikro && "text--mikro"}`}
        >
          {props.textunten}
        </p>
      </div>
      {props.spenden && (
      <a href={spendenUrl} target="_blank" rel="noreferrer">
        <div className="spenden--button">
          {ctaText}
        </div>
      </a>
      )}
    </article>
  );
};

export default SinglePage;