import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./LangMenu.css";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import PolygonDown from "../../shared/assets/polygon-down.svg";
import PolygonUp from "../../shared/assets/polygon-up.svg";

export default function LangMenu() {
const { fund, lang } = useParams();
let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chosenLang, setChosenLang] = React.useState(lang);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLang = (lang) => {
      setChosenLang(lang);
      //console.log(lang);
      handleClose();
      navigate(`/${fund}/${lang}`);
  }

  return (
    <div className="lang--menu">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {chosenLang}
      </Button>
      <img src={open ? PolygonUp : PolygonDown} className="polygon" alt="polygon" />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}        
      >
          {chosenLang !== "DE" && (
        <MenuItem onClick={() => handleLang("DE")}>DE</MenuItem>
        )}
        {chosenLang !== "FR" && (
        <MenuItem onClick={() => handleLang("FR")}>FR</MenuItem>
        )}
        {chosenLang !== "IT" && (
        <MenuItem onClick={() => handleLang("IT")}>IT</MenuItem>
        )}
        {chosenLang !== "EN" && (
        <MenuItem onClick={() => handleLang("EN")}>EN</MenuItem>
        )}
      </Menu>
    </div>
  );
}
