import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Flipbook from "./flipbook/pages/Flipbook";

function App() {
  const userLang = navigator.language || navigator.userLanguage; 
  const lang = userLang.toLowerCase().includes("en") ? "EN" : userLang.toLowerCase().includes("it") ? "IT" : userLang.toLowerCase().includes("fr") ? "FR" : "DE";

//console.log (`Browsersprache DE: ${lang} (${userLang})`);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/:fund/:lang/" element={<Flipbook />} />
          <Route exact path="/" element={<Navigate replace to={`/0/${lang}`} />} />
          <Route path="/*" element={<Navigate replace to={`/0/${lang}`} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
