import React from "react";

import SinglePage from "./SinlgePage";

const Spenden = (props) => {
  return (
    <SinglePage
      spenden
      lang={props.lang}
      title={props.title}
      textoben={props.text}
      bild={props.bild}
      textunten=""
    />
  );
};

export default Spenden;
