import React from "react";

import "./TopBar.css";

import Logo from "../../shared/assets/_Start/PJ_Logo_Sti_ge.png";

const TopBar = (props) => {
  const title = props.title.toString().toUpperCase();
  let lang = props.lang;
  return (
    <div className="top--bar">
      <img className="logo" src={Logo} alt="Pro Juvnetute Logo" />
      {props.title && (
        <h1
          className={`header--title font--size-${lang} ${props.bigTitle && "font--big-title"}`}
        >
          {title}
        </h1>
      )}
    </div>
  );
};

export default TopBar;
