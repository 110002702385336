import React from "react";

import { useParams } from "react-router-dom";

import "./Flipbook.css";

import FlipArrow from "../../shared/assets/page-flip-arrow.svg";
import SpendenHerz from "../../shared/assets/spendenHerz.svg";

import StartBild from "../../shared/assets/_Start/Group.svg";
import AngstBild from "../../shared/assets/Angst/Illu_angst.svg";
import AufgeregtBild from "../../shared/assets/Aufgeregt/Illu_aufgeregt.svg";
import AusgeglichenBild from "../../shared/assets/Ausgeglichen/Illu_ausgeglichen.svg";
import BegeistertBild from "../../shared/assets/Begeistert/Illu_begeistert.svg";
import BeliebtBild from "../../shared/assets/Beliebt/Illu_beliebt.svg";
import BeruehrtBild from "../../shared/assets/Berührt/Illu_berührt.svg";
import BeschaemtBild from "../../shared/assets/Beschämt/Illu_beschämt.svg";
import BesorgtBild from "../../shared/assets/Besorgt/Illu_besorgt.svg";
import DurcheinanderBild from "../../shared/assets/Durcheinander/Illu_durcheinander.svg";
import EiversuechtigBild from "../../shared/assets/Eifersüchtig/Illu_eifersüchtig.svg";
import EinsamBild from "../../shared/assets/Einsam/Illu_einsam.svg";
import EnttaeuschtBild from "../../shared/assets/Enttäuscht/Illu_enttäuscht.svg";
import ErleichtertBild from "../../shared/assets/Erleichtert/Illu_erleichtert.svg";
import FremdBild from "../../shared/assets/Fremd/Illu_fremd.svg";
import GelangweiltBild from "../../shared/assets/Gelangweilt/Illu_gelangweilt.svg";
import GleichgueltigBild from "../../shared/assets/Gleichgültig/Illu_gleichgueltig.svg";
import GluecklickBild from "../../shared/assets/Glücklich/Illu_gluecklich.svg";
import HilflosBild from "../../shared/assets/Hilflos/Illu_hilflos.svg";
import InteressiertBild from "../../shared/assets/Interessiert/Illu_interessiert.svg";
import KrankBild from "../../shared/assets/Krank/Illu_krank.svg";
import MitfuehlendBild from "../../shared/assets/Mitfühlend/Illu_mitfuehlend.svg";
import MuedeBild from "../../shared/assets/Müde/Illu_muede.svg";
import NachdenklichBild from "../../shared/assets/Nachdenklich/Illu_nachdenklich.svg";
import SchuldigBild from "../../shared/assets/Schuldig/Illu_schuldig.svg";
import SicherBild from "../../shared/assets/Sicher/Illu_sicher.svg";
import StarkBild from "../../shared/assets/Stark/Illu_stark.svg";
import StolzBild from "../../shared/assets/Stolz/Illu_Stolz.svg";
import StreitlustigBild from "../../shared/assets/Streitlustig/Illu_Streitlustig.svg";
import TraurigBild from "../../shared/assets/Traurig/Illu_traurig.svg";
import UeberfordertBild from "../../shared/assets/Überfordert/Illu_überfordert.svg";
import ÜbermütigBild from "../../shared/assets/Übermütig/Illu_übermütig.svg";
import UnbeliebtBild from "../../shared/assets/Unbeliebt/Illu_unbeliebt.svg";
import UnruhigBild from "../../shared/assets/Unruhig/Illu_unruhig.svg";
import UnsicherBild from "../../shared/assets/Unsicher/Illu_unsicher.svg";
import UnterDruckBild from "../../shared/assets/UnterDruck/Illu_unter_Druck.svg";
import VerletztBild from "../../shared/assets/Verletzt/Illu_verletzt.svg";
import VerliebtBild from "../../shared/assets/Verliebt/Illu_verliebt.svg";
import VerzweifeltBild from "../../shared/assets/Verzweifelt/Illu_verzweifelt.svg";
import WertlosBild from "../../shared/assets/Wertlos/Illu_wertlos.svg";
import WertvollBild from "../../shared/assets/Wertvoll/Illu_wertvoll.svg";
import WütendBild from "../../shared/assets/Wütend/Illu_wuetend.svg";
import ZufriedenBild from "../../shared/assets/Zufrieden/Illu_zufrieden.svg";

import FlipPage from "react-flip-page";
import TopBar from "../components/TopBar";
import SinglePage from "../components/SinlgePage";
import LangMenu from "../components/LangMenu";
import Spenden from "../components/Spenden";

const content = {
  start: [
    {
      stimmungsflip: "Stimmungsflip",
      blaettern: "Jetzt blättern",
    },
    {
      stimmungsflip: "Calendrier des humeurs",
      blaettern: "feuilleter",
    },
    {
      stimmungsflip: "Calendario dei sentimenti",
      blaettern: "Sfoglia ora",
    },
    {
      stimmungsflip: "Mood flip",
      blaettern: "flip",
    },
  ],
  subject: [
    {
      habe: "Ich habe",
      bin: "Ich bin",
      fuehle: "Ich fühle mich",
    },
    {
      habe: "J'ai",
      bin: "Je suis",
      fuehle: "Je me sens",
    },
    {
      habe: "Io ho",
      bin: "Io sono",
      fuehle: "Mi sento",
    },
    {
      habe: "",
      bin: "I am",
      fuehle: "I feel",
    },
  ],
  emotion: {
    angst: ["Angst", "angoissé/e", "paura", "afraid"],
    aufgeregt: ["aufgeregt", "énervé/e", "nervoso/a", "nervous"],
    ausgeglichen: [
      "ausgeglichen",
      "quelqu'un d'équilibré",
      "sereno/a",
      "balanced",
    ],
    begeistert: ["begeistert", "excité/e", "euforico/a", "excited"],
    beliebt: ["beliebt", "apprécié/e", "apprezato/a", "popular"],
    beruehrt: ["berührt", "touché/e", "commosso", "touched"],
    beschaemt: ["beschämt", "honteux/se", "vergogno", "ashamed"],
    besorgt: ["besorgt", "préoccupé/e", "preoccupato/a", "worried"],
    durcheinander: [
      "durcheinander",
      "tourneboulé/e",
      "pensieroso/a",
      "confused",
    ],
    eifersuechtig: ["eifersüchtig", "jaloux/se", "geloso/a", "jealous"],
    einsam: ["einsam", "seul/e", "solo/a", "lonley"],
    enttaeuscht: ["enttäuscht", "déçu/e", "deluso/a", "disappointed"],
    erleichtert: ["erleichtert", "soulagé/e", "sollevato/a", "relieved"],
    fremd: ["fremd", "différent/e", "diverso/a", "strange"],
    gelangweit: ["gelangweilt", "m'ennuie", "annoio", "bored"],
    gleichgueltig: ["gleichgültig", "indifférent/e", "diverso/a", "strange"],
    gluecklich: ["glücklich", "heureux/se", "felice", "happy"],
    hilflos: ["hilflos", "désemparé/e", "indifeso/a", "helpless"],
    interessiert: ["interessiert", "curieux/se", "curioso/a", "curious"],
    krank: ["krank", "malade", "ammalato/a", "ill"],
    mitfuehlend: [
      "mitfühlend",
      "compatissant/e",
      "compassionevole",
      "compassionate",
    ],
    muede: ["müde", "fatigué/e", "stanco/a", "tired"],
    nachdemklich: ["nachdenklich", "pensif/ve", "pensieroso/a", "thoughtful"],
    schuldig: ["schuldig", "coupable", "colpa", "guilty"],
    sicher: ["sicher", "sûr/e", "sicuro/a", "safe"],
    stark: ["stark", "fort/e", "forte", "strong"],
    stolz: ["stolz", "fier/ère", "fiero/a", "proud"],
    streitlustig: [
      "streitlustig",
      "bagarreur/se",
      "litigioso/a",
      "argumentative",
    ],
    traurig: ["traurig", "triste", "triste", "sad"],
    ueberfordert: ["überfordert", "dépassé/e", "esausto/a", "overwhelmed"],
    uebermuetig: ["übermütig", "enthousiaste", "entusiasta", "overconfident"],
    unbeliebt: ["unbeliebt", "impopulaire", "impopolare", "unpopular"],
    unruhig: ["unruhig", "nerveux/se", "inquieto/a", "restless"],
    unsicher: ["unsicher", "peu sûr/e de moi", "insicuro/a", "insecure"],
    unterdruck: [
      "unter Druck",
      "sous pression",
      "sotto pressione",
      "under pressure",
    ],
    wuetend: ["wütend", "furieux/se", "arrabiato/a", "angry"],
    wertlos: ["wertlos", "sans valeur", "privo/a di valore", "worthless"],
    verletzt: ["verletzt", "blessé/e", "ferito/a", "hurt"],
    verliebt: ["verliebt", "amoureux/se", "innamorato/a", "in love"],
    verzweifelt: ["verzweifelt", "désespéré/e", "disperato/a", "desperate"],
    wertvoll: ["wertvoll", "valorisé/e", "poco apprezzato/a", "valued"],
    zufrieden: ["zufrieden", "content/e", "contento/a", "content"],
  },
};

const Flipbook = () => {
  // fund 0 = öffentlich (mit Spenden-Kärtchen)
  // fund 1 = fachpersonen (ohne Spenden-Kärtchen)
  let { fund, lang } = useParams();
  lang = lang.toUpperCase();
  const l = lang === "EN" ? 3 : lang === "IT" ? 2 : lang === "FR" ? 1 : 0;
  const spendenText =
    lang === "IT"
      ? "sostenerci"
      : lang === "FR"
      ? "soutiens-nous"
      : lang === "EN"
      ? "support us"
      : "unterstütze uns";
  //console.log(`fund: ${fund}`);
  return (
    <div className="wrapper">
      <div className="flipbook">
        <FlipPage pageBackground="#ffffff" responsive>
          <article style={{ backgroundColor: "#FECC33" }}>
            <LangMenu />
            <TopBar
              title={content.start[l].stimmungsflip}
              bigTitle
              lang={lang}
            />
            <div className="start--container">
              <img src={StartBild} alt="Start Bild" />
              <div className="start--text">{content.start[l].blaettern}</div>
              <img className="flip--arrow" src={FlipArrow} alt="flip arrow" />
            </div>
          </article>

          {fund === "0" ? (
            <Spenden
              lang={lang}
              text={spendenText}
              bild={SpendenHerz}
              title={content.start[l].stimmungsflip}
            />
          ) : (
            <SinglePage
              lang={lang}
              title={content.start[l].stimmungsflip}
              textoben={
                lang === "FR" || lang === "IT"
                  ? content.subject[l].fuehle
                  : content.subject[l].bin
              }
              bild={StarkBild}
              textunten={content.emotion.stark[l]}
            />
          )}
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleiner={lang === "FR"}
            textoben={
              lang === "EN"
                ? content.subject[3].bin
                : lang === "FR"
                ? content.subject[1].fuehle
                : content.subject[l].habe
            }
            bild={AngstBild}
            textunten={content.emotion.angst[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleiner
            textoben={content.subject[l].bin}
            bild={AufgeregtBild}
            textunten={content.emotion.aufgeregt[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleinster
            textoben={
              lang === "FR" ? content.subject[1].bin : content.subject[l].fuehle
            }
            bild={AusgeglichenBild}
            textunten={content.emotion.ausgeglichen[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "DE" || lang === "EN"
                ? content.subject[l].bin
                : content.subject[l].fuehle
            }
            bild={BegeistertBild}
            textunten={content.emotion.begeistert[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={BeliebtBild}
            textunten={content.emotion.beliebt[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={BeruehrtBild}
            textunten={content.emotion.beruehrt[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "IT"
                ? "Mi"
                : lang === "FR"
                ? content.subject[l].bin
                : content.subject[l].fuehle
            }
            bild={BeschaemtBild}
            textunten={content.emotion.beschaemt[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={BesorgtBild}
            textunten={content.emotion.besorgt[l]}
            textKleinster
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleinster
            textoben={
              lang === "FR" ? content.subject[l].fuehle : content.subject[l].bin
            }
            bild={DurcheinanderBild}
            textunten={content.emotion.durcheinander[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={EiversuechtigBild}
            textunten={content.emotion.eifersuechtig[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={EinsamBild}
            textunten={content.emotion.einsam[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={EnttaeuschtBild}
            textunten={content.emotion.enttaeuscht[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "FR" || lang === "IT"
                ? content.subject[l].fuehle
                : content.subject[l].bin
            }
            bild={ErleichtertBild}
            textunten={content.emotion.erleichtert[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={FremdBild}
            textunten={content.emotion.fremd[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "IT"
                ? "Mi"
                : lang === "FR"
                ? "Je"
                : content.subject[l].bin
            }
            bild={GelangweiltBild}
            textunten={content.emotion.gelangweit[l]}
            textKleinster
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={GleichgueltigBild}
            textunten={content.emotion.gleichgueltig[l]}
            textKleinster
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={GluecklickBild}
            textunten={content.emotion.gluecklich[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={HilflosBild}
            textunten={content.emotion.hilflos[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleiner
            textoben={content.subject[l].bin}
            bild={InteressiertBild}
            textunten={content.emotion.interessiert[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "FR" || lang === "IT"
                ? content.subject[l].bin
                : content.subject[l].fuehle
            }
            bild={KrankBild}
            textunten={content.emotion.krank[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={MitfuehlendBild}
            textunten={content.emotion.mitfuehlend[l]}
            textMikro={lang === "IT"}
            textKleinster={lang !== "IT"}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "FR" || lang === "IT"
                ? content.subject[l].fuehle
                : content.subject[l].bin
            }
            bild={MuedeBild}
            textunten={content.emotion.muede[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleinster
            textoben={content.subject[l].bin}
            bild={NachdenklichBild}
            textunten={content.emotion.nachdemklich[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={SchuldigBild}
            textunten={content.emotion.schuldig[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={SicherBild}
            textunten={content.emotion.sicher[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={StolzBild}
            textunten={content.emotion.stolz[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "FR" || lang === "IT"
                ? content.subject[l].fuehle
                : content.subject[l].bin
            }
            bild={StreitlustigBild}
            textunten={content.emotion.streitlustig[l]}
            textKleinster
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={TraurigBild}
            textunten={content.emotion.traurig[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleiner
            textoben={
              lang === "EN" ? content.subject[l].bin : content.subject[l].fuehle
            }
            bild={UeberfordertBild}
            textunten={content.emotion.ueberfordert[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={ÜbermütigBild}
            textunten={content.emotion.uebermuetig[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={UnbeliebtBild}
            textunten={content.emotion.unbeliebt[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "FR" ? content.subject[l].fuehle : content.subject[l].bin
            }
            bild={UnruhigBild}
            textunten={content.emotion.unruhig[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={
              lang === "DE" ? content.subject[l].bin : content.subject[l].fuehle
            }
            bild={UnsicherBild}
            textunten={content.emotion.unsicher[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleiner
            textoben={content.subject[l].fuehle}
            bild={UnterDruckBild}
            textunten={content.emotion.unterdruck[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={VerletztBild}
            textunten={content.emotion.verletzt[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={VerliebtBild}
            textunten={content.emotion.verliebt[l]}
            textKleinster
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleiner
            textoben={content.subject[l].fuehle}
            bild={VerzweifeltBild}
            textunten={content.emotion.verzweifelt[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].fuehle}
            bild={WertlosBild}
            textunten={content.emotion.wertlos[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textKleinster
            textoben={content.subject[l].fuehle}
            bild={WertvollBild}
            textunten={content.emotion.wertvoll[l]}
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={WütendBild}
            textunten={content.emotion.wuetend[l]}
            textKleiner
          />
          <SinglePage
            lang={lang}
            title={content.start[l].stimmungsflip}
            textoben={content.subject[l].bin}
            bild={ZufriedenBild}
            textunten={content.emotion.zufrieden[l]}
            textKleiner
          />
          {fund === "0" && (
            <SinglePage
              lang={lang}
              title={content.start[l].stimmungsflip}
              textoben={
                lang === "FR" || lang === "IT"
                  ? content.subject[l].fuehle
                  : content.subject[l].bin
              }
              bild={StarkBild}
              textunten={content.emotion.stark[l]}
            />
          )}
        </FlipPage>
      </div>
    </div>
  );
};

export default Flipbook;
